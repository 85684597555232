import Vue from "vue";
import Vuex from "vuex";
import login from "./login.js";
import dayjs from "dayjs";

Vue.use(Vuex);
const store = new Vuex.Store({
    namespaced: true,
    state: {
        theme: 'white',    //deepcolour
        fangan: 'B',
        dark: false,
        shareShow: false,
        // selectDate: dayjs().format('YYYYMMDD'),
		selectDate: '',
		analysisData:[],
		analysisProductlist:[],
		analysisTotal:0,
		analysisDate:{},
		analysisVanillaQueryData:{},
		
		analysisQueryData:{},
		
		homepage_stock:'',
		news_id:0,
		siteInfo:{},
    },
    mutations: {
        // 更换日期方法
        changeSelectDate(state, payload) {
            state.selectDate = payload.selectDate
        },
        // 新版和旧版切换方案
        changeFangAn(state, payload) {
            if (state.fangan === 'A') {
                state.fangan = 'B'
            } else {
                state.fangan = 'A'
            }
        },
        changeDark(state, payload) {
            state.dark = payload.dark
        },
        changeShareStatus(state, payload) {
            state.shareShow = payload.shareShow
        },
		updateAnalysis(state, payload) {
				state.analysisData = payload;
		},
		updateProduct(state, payload) {
				state.analysisProductlist = payload;
		},
		updateAnalysisTotal(state, payload) {
				state.analysisTotal = payload;
		},
				
		updateAnalysisDate(state, payload) {
				state.analysisDate = payload;
		},
		
		updateAnalysisQueryData(state, payload) {
				state.analysisQueryData = payload;
		},
		
		updateHomepageStock(state, payload) {
				state.homepage_stock = payload;
		},
		
		//新闻分类ID
		updateNewsId(state, payload) {
				state.news_id = payload;
		},
		updateSiteInfo(state, payload) {
				state.siteInfo = payload;
		},
		
    },
    actions: {},
    getters: {
        userinfo: state => state.login.userinfo,
        initData: state => state.login.initData
    },
    modules: {
        login,
    },
});

export default store;
