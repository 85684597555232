import { mapState } from "vuex";
export default {
    data() {
        return {
            color: "red"
        };
    },
    mounted() {
        // this.changeColor("themea");
    },
    computed: {
        ...mapState(["theme"])
    },
    watch: {
        theme() {
            return {
                "--color": this.color
            };
        }
    },
    methods: {
        changeColor(command) {
            document.getElementById("app").className = command;
            this.localStorageDate();
        },
        localStorageDate() {
            localStorage.setItem('app', document.getElementById('app').className)
        }
    }
};