import Vue from "vue";
import App from "./App.vue";
import router from "./router/index.js";
import "element-ui/lib/theme-chalk/index.css";
import './theme/variables.less'
import promise from "es6-promise";
import './styles/tailwind.css'
import store from "@/store";

import './directives.js';
import {
  Alert,
  Aside,
  Autocomplete,
  Backtop,
  Badge,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  ButtonGroup,
  Calendar,
  Card,
  Carousel,
  CarouselItem,
  Cascader,
  CascaderPanel,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  Col,
  Collapse,
  CollapseItem,
  ColorPicker,
  Container,
  DatePicker,
  Dialog,
  Divider,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Footer,
  Form,
  FormItem,
  Header,
  Icon,
  Image,
  Input,
  InputNumber,
  Link,
  Loading,
  Main,
  Menu,
  MenuItem,
  MenuItemGroup,
  Message,
  MessageBox,
  Notification,
  Option,
  OptionGroup,
  PageHeader,
  Pagination,
  Popconfirm,
  Popover,
  Progress,
  Radio,
  RadioButton,
  RadioGroup,
  Rate,
  Row,
  Select,
  Slider,
  Spinner,
  Step,
  Steps,
  Submenu,
  Switch,
  Table,
  TableColumn,
  TabPane,
  Tabs,
  Tag,
  Timeline,
  TimelineItem,
  TimePicker,
  TimeSelect,
  Tooltip,
  Transfer,
  Tree,
  Upload,
} from "element-ui";
// import 'default-passive-events'
import dayjs from "dayjs";

require("promise.prototype.finally").shim();
promise.polyfill();
Vue.use(Pagination);
Vue.use(Dialog);
Vue.use(Autocomplete);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);
Vue.use(MenuItemGroup);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(RadioButton);
Vue.use(Checkbox);
Vue.use(CheckboxButton);
Vue.use(CheckboxGroup);
Vue.use(Switch);
Vue.use(Select);
Vue.use(Option);
Vue.use(OptionGroup);
Vue.use(Button);
Vue.use(ButtonGroup);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(DatePicker);
Vue.use(TimeSelect);
Vue.use(TimePicker);
Vue.use(Popover);
Vue.use(Popconfirm);
Vue.use(Tooltip);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Tag);
Vue.use(Tree);
Vue.use(Alert);
Vue.use(Slider);
Vue.use(Icon);
Vue.use(Row);
Vue.use(Col);
Vue.use(Upload);
Vue.use(Progress);
Vue.use(Spinner);
Vue.use(Badge);
Vue.use(Card);
Vue.use(Rate);
Vue.use(Steps);
Vue.use(Step);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Cascader);
Vue.use(ColorPicker);
Vue.use(Transfer);
Vue.use(Container);
Vue.use(Header);
Vue.use(Aside);
Vue.use(Main);
Vue.use(Footer);
Vue.use(Timeline);
Vue.use(TimelineItem);
Vue.use(Link);
Vue.use(Divider);
Vue.use(Image);
Vue.use(Calendar);
Vue.use(Backtop);
Vue.use(PageHeader);
Vue.use(CascaderPanel);
Vue.prototype.$loading = Loading.service;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;
Vue.prototype.$bus = new Vue();
Vue.prototype.$handleWindowResize = function handleWindowResize(callback) { // 全局监听页面大小，调整暗黑模式

     let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    if(flag){
		console.log('手机或pad')
		const changeDarkHandle = (isDark) => {
		  // do something
		  callback(isDark); // 回调函数，将 isDark 作为参数传递给回调函数
		};
		const resizeHandler = () => {
		  let w = window.innerWidth;
		  if (w <= 900) {
		    changeDarkHandle(true);
		  } else {
		    changeDarkHandle(false);
		  }
		};
		// 监听窗口大小变化事件
		window.addEventListener('resize', resizeHandler);
		// 初始化时执行一次
		resizeHandler();
	}else{
   
  const changeDarkHandle = (isDark) => {
    // do something
    callback(isDark); // 回调函数，将 isDark 作为参数传递给回调函数
  };
  const resizeHandler = () => {
    let w = window.innerWidth;
    if (w <= 1200) {
      changeDarkHandle(true);
    } else {
      changeDarkHandle(false);
    }
  };
  // 监听窗口大小变化事件
  window.addEventListener('resize', resizeHandler);
  // 初始化时执行一次
  resizeHandler();
  }
}

	//  import {Quill} from 'vue-quill-editor' 
	// window.Quill = Quill
	
	// const scriptEl = document.createElement('script'); 
	// scriptEl.charset = 'utf-8' 
	// scriptEl.src =  './image-resize.min.js' 
	// const head = document.head || document.getElementsByTagName('head')[0]; 
	// head.appendChild(scriptEl);


Vue.config.productionTip = false;

Vue.use(Loading.directive);
// import './utils/rem'
Vue.prototype.$dayjs = dayjs;
 new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

