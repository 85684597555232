<template>
  <!-- <div id="app" v-if="fangan==='A'"> -->
    <!-- <div class="mycontainer">
      <el-aside>
        <topView></topView>
        左侧导航
        <tabula></tabula>
        <div class="funbtn_logo">
          <img :src="getdata().logo" alt="" v-if="getdata()">
          <img src="@/assets/cw.png" alt v-else />
        </div>
      </el-aside>
      中间内容
      <el-main>
        <keep-alive>
          <router-view></router-view>
        </keep-alive>
      </el-main>
    </div>
    <div class="function">
      <div class="list" v-if="openflag" :class="openflag ? 'fadein' : 'fadeout'">
        <funbtn class="funbtn"></funbtn>
      </div>
      <div class="close">
        <i class="el-icon-question" v-show="!openflag" @click="openflag = !openflag"></i>
        <i class="el-icon-error" v-show="openflag" @click="openflag = !openflag"></i>
      </div>
    </div> -->
  <!-- </div> -->
  <div id="app">
	  <el-dialog
	        title="正在更新版本,请稍后 ···"
	        :visible.sync="dialogVisible"
	        width="60%"
	        :close-on-click-modal="closeOnClickModal"
	        :close-on-press-escape="closeOnPressEscape"
	        :show-close="showClose"
	        center
			>
	        <div style="width:100%;height:5vh;line-height:5vh;text-align:center">
	          <el-progress
	            status="success"
	            :text-inside="true"
	            :stroke-width="20"
	            :percentage="percentage"
	            :width="strokeWidth"
	            :show-text="true"
	          ></el-progress>
	        </div>
	      </el-dialog>
    <router-view>
	场外数据
    </router-view>
  </div>
</template>



<script>
	// const { ipcRenderer } = window.require('electron')
	
// import TabulaTion from "./views/layout/sidebar/index.vue";
// import topView from "./views/layout/head/index.vue";
// import funbtn from "./views/layout/sidebar/funbtn.vue";
import mixins from "@/mixins/index";
import {mapState} from "vuex";
export default {
  name: "App",
  components: {
    // tabula: TabulaTion,
    // topView,
    // funbtn
  },
  mixins: [mixins],
  data() {
    return {
      openflag: false,
	  
		dialogVisible: false,
		closeOnClickModal: false,
		closeOnPressEscape: false,
		showClose: false,
		percentage: 0,
		strokeWidth:200,
	
    }
  },
  computed:{
    ...mapState(['fangan'])
  },
  mounted () {
	  // this.shengji();
	},
  methods: {
    getdata() {
		console.log('app.getdata')
      // return JSON.parse(localStorage.getItem("initData"))
    },
	
	shengji(){
		let self = this;
		//接收主进程版本更新消息
		ipcRenderer.on("message", (event, arg) => {
		  console.log(arg);
		  if ("update-available" == arg.cmd) {
		    //显示升级对话框
		    self.dialogVisible = true;
		  } else if ("download-progress" == arg.cmd) {
		    // console.log(arg.message.percent);
		    let percent = Math.round(parseFloat(arg.message.percent));
		    self.percentage = percent;
		  } else if ("error" == arg.cmd) {
		    self.dialogVisible = false;
		    self.$message(arg);
		  } else if ("update-not-available" == arg.cmd) {
		    // self.$message(arg);
		  }
		});
		//开始检测新版本
		let timeOut = window.setTimeout(() => {
		  ipcRenderer.send("checkForUpdate");
		  console.log('开始检测更新触发')
		}, 500);
		clearTimeout;
	}
	
  }
};

</script>

<style lang="less">
	@import '@/assets/css/global-141.css';
	
	@import '@/assets/iconfont/iconfont.css';
	// @import 'https://assets.changwaidata.com/page/fonts/fonts.css';
	// @import '@/assets/fonts/fonts.css';
	.custom-font {
	  // font-family: 'myFirstFont';
	}
	/*
#app {
  font-family: Arial,PingFangSC-Regular,Microsoft YaHei,Helvetica,Simsun,sans-serif;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  color: #1e2024;
}

.el-aside {
  background: #26252d;
  overflow-x: hidden;
  height: 100vh;
  width: 80px !important;

  .funbtn_logo {
    //width: 100%;
    height: calc(100% - 650px);
    display: flex;
    align-items: flex-end;
    //justify-content: center;
    padding-top: 50px;
    transform: translate(10px,10px);
    position: absolute;
    left: 0;
    bottom: 40px;

    img {
      width: 56px;
    }
  }
}

.el-input__inner {
  border: none !important;
  background: #f7f7f7 !important;
}

.el-main {
  padding: 0 !important;
  height: auto;
  overflow: auto;
}

/deep/ .mycontainer {
  height: 100vh;
  overflow: auto;
  display: flex;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.function {
  position: fixed;
  right: 50px;
  bottom: 50px;
  z-index: 999;

  .close {
    height: 30px;
    border-radius: 50%;
    text-align: right;
    line-height: 30px;
    font-size: 40px;
  }

  .list {
    width: 140px;
    // height: 300px;
    background: #26252d;
    border-radius: 10px;
  }

  .fadein {
    opacity: 0;
    animation: fadeIn 1s forwards;
  }

  .fadeout {
    opacity: 1;
    animation: fadeout 1s forwards;
  }
}*/
</style>
