import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";
import { Message } from "element-ui";
// Vue.use(VueRouter)
Vue.use(VueRouter);

// 重构的页面
const newRouters = [
    {
        path: "/tmp",
        component: () => import("../components/shareItem.vue"),
    },
    // 分析页面
    {
        path: "/Analysis",
        name: "Analysis",
        component: () => import("../views/pages/v-2/anl/Anl.vue"),
        meta: {
            title: "分析",
            keepAlive: true //需要被缓存的组件
        },
    },
    {
        path: "/case",
        name: "case",
        component: () => import("../views/pages/v-2/case/Case.vue"),
        meta: {
            title: "案例",
            keepAlive: true //需要被缓存的组件
        },
    },
    {
        path: "/custom",
        name: "custom",
        component: () => import("../views/pages/v-2/custom/index.vue"),
        meta: {
            title: "自选",
            keepAlive: true //需要被缓存的组件
        },
    },
    {
        path: "/pos",
        name: "pos",
        component: () => import("../views/pages/v-2/pos/index.vue"),
        meta: {
            title: "持仓",
            keepAlive: true //需要被缓存的组件
        },
    },
    {
        path: "/team",
        name: "team",
        component: () => import("../views/pages/v-2/team/index.vue"),
        meta: {
            title: "团队",
            keepAlive: false //需要被缓存的组件
        },
    },
    {
        path: "/news",
        name: "news",
        component: () => import("../views/pages/v-2/news/index.vue"),
        meta: {
            title: "知识",
            keepAlive: false //需要被缓存的组件
        },
    }, {
        path: "/infomation",
        name: "infomation",
        component: () => import("../views/pages/v-2/infomation/index.vue"),
        meta: {
            title: "资讯",
            keepAlive: false //需要被缓存的组件
        },
    },
    {
        path: "/my",
        name: "my",
        component: () => import("../views/pages/v-2/my/index.vue"),
        meta: {
            title: "我的",
            keepAlive: false //需要被缓存的组件
        },
    },
    {
        path: "/down",
        name: "down",
        component: () => import("../views/DownLoadPage.vue"),
        meta: {
            title: "下载",
            keepAlive: false //需要被缓存的组件
        },
    },
    {
        path: "/institution",
        name: "institution",
        component: () => import("../views/institution.vue"),
        meta: {
            title: "机构入驻",
            keepAlive: false //需要被缓存的组件
        },
    }, {
        path: "/yan",
        name: "yan",
        component: () => import("../views/yan.vue"),
        meta: {
            title: "衍圈",
            keepAlive: false //需要被缓存的组件
        },
    }, {
        path: "/klinepage",
        name: "klinepage",
        component: () => import("../views/klinepage.vue"),
        meta: {
            title: "K线资料",
            keepAlive: false //需要被缓存的组件
        },
    }, {
        path: "/workbench",
        name: "workbench",
        component: () => import("../views/workbench.vue"),
        meta: {
            title: "工作台",
            keepAlive: false //需要被缓存的组件
        },
    },
]
const routes = [
    {
        path: "/",
        // redirect重定向
        // redirect: "IndividualShare",
        redirect: "/home",
        component: () => import("../views/pages/v-2/home/Index"),
        meta: {
            title: "首页",
        },
    },
    {
        path: "/home",
        name: "home",
        component: () => import("../views/pages/v-2/home/Index"),
        meta: {
            title: "首页",
            keepAlive: true //需要被缓存的组件
        },
    },
    {
        path: "/news",
        name: "news",
        component: () => import("../views/pages/v-2/news/index.vue"),
        meta: {
            title: "资讯",
            keepAlive: false //需要被缓存的组件
        },
    },
    // {
    //     path: "/IndividualShare",
    //     name: "IndividualShare",
    //     component: () => import("../views/IndividualShare.vue"),
    //     meta: {
    //         title: "个股",
    //         keepAlive: true //需要被缓存的组件
    //     },
    // },
    // {
    //     path: "/MarketPlace",
    //     name: "MarketPlace",
    //     component: () => import("../views/MarketPlace.vue"),
    //     meta: {
    //         title: "市场",
    //         keepAlive: true //需要被缓存的组件
    //     },
    // },
    // {
    //     path: "/OptionalStock",
    //     name: "OptionalStock",
    //     component: () => import("../views/OptionalStock.vue"),
    //     meta: {
    //         title: "自选",
    //         keepAlive: true //需要被缓存的组件
    //     },
    // },
    // {
    //     path: "/HoldAPosition",
    //     name: "HoldAPosition",
    //     component: () => import("../views/HoldAPosition.vue"),
    //     meta: {
    //         title: "持仓",
    //         keepAlive: true //需要被缓存的组件
    //     },
    // },
    // {
    //     path: "/team",
    //     name: "team",
    //     component: () => import("../views/team.vue"),
    //     meta: {
    //         title: "团队",
    //         keepAlive: false //需要被缓存的组件
    //     },
    // },
    // {
    //     path: "/PersonalCenter",
    //     name: "PersonalCenter",
    //     component: () => import("../views/PersonalCenter.vue"),
    //     meta: {
    //         title: "我的",
    //         keepAlive: false //需要被缓存的组件
    //     },
    // },
    // {
    //     path: "/case",
    //     name: "case",
    //     component: () => import("../views/case.vue"),
    //     meta: {
    //         title: "案例",
    //         keepAlive: true //需要被缓存的组件
    //     },
    // },
    {
        path: "/HelloWorld",
        name: "HelloWorld",
        component: () => import("../components/HelloWorld.vue"),
    },
    {
        path: "/simulatedPosition",
        name: "simulatedPosition",
        component: () =>
            import("../components/HoldAPosition/simulatedPosition.vue"),
    },
    ...newRouters,
    {
        path: "*",
        redirect: '/home'
    }

];

function getLocationParams(keyWords) {
    let href = window.location.href;
    let query = href.substring(href.indexOf("?") + 1);
    let vars = query.split("&");
    for (let i = 0; i < vars.length; i++) {
        let pair = vars[i].split("=");
        if (pair[0] == keyWords) {
            return pair[1];
        }
    }
    return "";
}

const router = new VueRouter({
    // 配置路由和组件之间的应用关系
    routes,
    mode: "history",
    // hash
    linkActiveClass: "active",
});
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

router.beforeEach((to, from, next) => {
    let token = store.state.login.token || localStorage.getItem("token");
    if (to.path == '/home') {
        localStorage.removeItem("promotion")
        localStorage.setItem("promotion", getLocationParams('promotion'))
        next()
    }
    else if (to.path == '/news') {
        // localStorage.removeItem("promotion")
        // localStorage.setItem("promotion", getLocationParams('promotion'))
        next()
    }
    else {
        if (token) {
            localStorage.removeItem("promotion")
            localStorage.setItem("promotion", getLocationParams('promotion'))
            next()
        } else {
            Message({
                type: 'error',
                message: "登录后再操作"
            })
            localStorage.removeItem("promotion")
            localStorage.setItem("promotion", getLocationParams('promotion'))
            next({ path: '/home' })
        }
    }
})
export default router;
